import React from 'react'
import Testimonial from '../Testimonial/Testimonial'
import Home from '../../Pages/HomePage/Home'

const Homemain = () => {
  return (
    <>
    <Home />
  <Testimonial />
    </>
  )
}

export default Homemain